<template>
  <b-button
    :pill="pill"
    :type="type"
    :variant="variant"
    :block="block"
    :disabled="disabled"
    :size="size"
    class="wameed-btn d-flex justify-content-center align-items-center"
    :class="classes"
    :style="hideTitleOnMoile? { 'min-width': 'auto' } : {}"
    @click="submitAction"
  >
    <component
      :is="appendIcon"
      v-if="appendIcon"
    />
    <samp :class="hideTitleOnMoile ? 'd-md-block d-none' : 'd-block'">
      {{ title }}
    </samp>

    <component
      :is="prependIcon"
      v-if="prependIcon"
      class="mx-2"
    />
  </b-button>
</template>
<script>
export default {
  props: {
    classes: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    hideTitleOnMoile: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    pill: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitAction() {
      this.$emit('submitAction');
    },
  },
};
</script>
